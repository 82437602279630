import {getList,save,update,remove,getOne,addSelectStudent} from "@/api/exp/QuestionBankApi";
import {CommonModel} from "@/model/CommonModel";
import axios from "axios";
import {API_URL_EXP_ADMIN} from "@/model/ConfigModel";
import {msg_err, msg_success} from "@/utils/ele_component";

/**
 课堂考核-题库model
 */
class QuestionBankModel {
  // 获取列表
  static async getList(page, size, query) {
    let params = {
      page: page,
      size: size,
    }
    params = Object.assign(params, query)
    let [data] = await getList(params)
    if (size === 0) {  // 不分页
      return data.data;
    } else { // 分页
      let list = CommonModel.generateListMybatisPlus(data.data)
      // 遍历config转换为对象
      let listData = list[0]
      return [listData, list[1]];
    }
  }

  // 新增
  static async save(entity) {
    let [res] = await save(entity)
    if (res.code === 20000) {
      return true
    } else {
      return false
    }
  }

  // 修改
  static async update(entity) {
    let [res] = await update(entity)
    if (res.code === 20000) {
      return true
    } else {
      return false
    }
  }

  // 删除
  static async remove(idArray) {
    let [res] = await remove(idArray)
    if (res.code === 20000) {
      return true
    } else {
      return false
    }
  }

  // 获取一个
  static async getOne(id) {
    let [res] = await getOne(id)
    if (res.code === 20000) {
      return res.data
    } else {
      return false
    }
  }
}

export {QuestionBankModel}
