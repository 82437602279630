import {request_async} from "@/utils/requestAsync";
import {API_URL_EXP_CONSUMER} from "@/model/ConfigModel";

// 获取列表
export async function getList(params) {
    return request_async(API_URL_EXP_CONSUMER + `consumer/questionBank/list`, "get", params);
}

// 新增
export async function save(params) {
    return request_async(API_URL_EXP_CONSUMER + `consumer/questionBank/save`, "post_json", params);
}

// 修改
export async function update(params) {
    return request_async(API_URL_EXP_CONSUMER + `consumer/questionBank/update`, "post_json", params);
}

// 删除
export async function remove(params) {
    return request_async(API_URL_EXP_CONSUMER + `consumer/questionBank/delete`, "post_json", params);
}

// 信息
export async function getOne(id) {
    return request_async(API_URL_EXP_CONSUMER + `consumer/questionBank/info/` + id, "get", {});
}
